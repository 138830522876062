import MaxWidth from 'components/functional/maxWidth'
import GalleryWrapper from 'components/gallery/galleryWrapper'
import Layout from 'components/global/layout'
import PageHeader from 'components/shared/pageHeader'
import { graphql } from 'gatsby'
import React from 'react'

const Gallery = ({ data }) => {
  const mob = data.mobHeaderImg
  const tab = data.tabletHeaderImg
  const desk = data.deskHeaderImg
  const images = data.images.nodes
  const meta = {
    title: 'Gallery | The Mousetrap',
  }
  return (
    <Layout title={meta.title}>
      <PageHeader mobileHeader={mob} tabletHeader={tab} desktopHeader={desk} />
      <MaxWidth $wooden>
        <GalleryWrapper data={images} />
        <strong className="accreditation">
          Cast photography by Matt Crockett
        </strong>
      </MaxWidth>
    </Layout>
  )
}

export default Gallery

export const GalleryQuery = graphql`
  query {
    mobHeaderImg: file(name: { eq: "6-mobile" }) {
      childImageSharp {
        gatsbyImageData(
          width: 575
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    tabletHeaderImg: file(name: { eq: "6-tablet" }) {
      childImageSharp {
        gatsbyImageData(
          width: 991
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    deskHeaderImg: file(name: { eq: "6-desktop" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    images: allGalleryJson {
      nodes {
        image {
          caption
          src {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: FULL_WIDTH
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
