import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'

import GetImage from 'components/functional/getImage'

const GalleryWrapperStyles = styled.div`
  width: 100%;
  position: relative;
  padding: 3rem 0;
  z-index: 2;
  h1 {
    text-align: center;
    @media ${media.md} {
      font-size: 3rem;
      margin-bottom: 2rem;
    }
    @media ${media.xl} {
      margin-bottom: 3rem;
    }
  }
  .wrapper {
    padding: 2rem 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
    div {
      cursor: pointer;
    }
 
  
    .gatsby-image-wrapper, img {
      @media ${media.md} {
        max-width: 500px !important;
      }
    }
    @media ${media.md} {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 2rem;
      .gatsby-image-wrapper {
        /* max-width: 650px!important; */
      }
    }
  }
  .SRLContainer {
    .SRLElementContainer {
      .SRLElementWrapper {
        margin-top: 20rem !important;
      }
    }
  }
`
const GalleryWrapper = ({ data }) => {
  const options = {
    settings: {
      autoplaySpeed: 3000,
  }
}
  return (
    <GalleryWrapperStyles>
      <h1>Gallery</h1>
      <SimpleReactLightbox>
        <SRLWrapper options={options}>
          <div className="wrapper">
            {data.map((image, i) => (
              <div key={i}>
                <GetImage data={image.image.src} alt={image.image.caption} />
              </div>
            ))}
          </div>
        </SRLWrapper>
      </SimpleReactLightbox>
    </GalleryWrapperStyles>
  )
}

export default GalleryWrapper
